import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { fonts } from "./fonts";
import { device } from "../utils/device";

export const GlobalStyles = createGlobalStyle`

  :root {
    --x: 0;
    --y: 0;
  }
  // Fonts
  ${fonts}

  // Normalize
  ${normalize}

  // Main
  *, ::after, ::before {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

  html, body {
    background-color: #fff;
    /* scroll-behavior: smooth; */
  }

  html {
    width: 100%;
    height: 100%;
    font-size: 62.5%; // 10px
    line-height: 1.5;
    background-color: #fff;
    overflow-x: hidden;
  }

  body {
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.palette.background};
    background-color: ${(props) => props.theme.palette.black};
    color: ${(props) => props.theme.palette.text};
    font-family: ${(props) => props.theme.fonts.gotham};
    font-weight: normal;
    text-rendering: optimizeSpeed;
    overscroll-behavior: none;
    overflow-x: hidden;
    background-color: #fff;
  }

  .gatsby-image-wrapper {
    position: relative;
    z-index: 0 !important;
  }

  section {
  }
`;
