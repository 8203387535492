import styled, { css, keyframes } from "styled-components";
import { Link } from "gatsby";

export const Container = styled(Link)<{
  color: "green" | "white" | "white-main" | "light-green";
  $opacity: boolean | undefined;
  width: string | undefined;
}>`
  position: relative;
  z-index: 5;
  font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  min-width: 19.6rem;
  min-height: 4.8rem;
  border: none;
  outline: 0;
  border-radius: 2.4rem;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: color 0.5s;
  ${(p) => p.width && `width: ${p.width};`}
  transform-origin: bottom center;
  animation-name: swing;
  animation-duration: 1s;
  animation-fill-mode: both;

  &::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%) scale(0);
    width: 400px;
    height: 400px;
    transition: all 0.5s;
    background-color: ${({ theme }) => theme.palette.darkestGreen};
    border-radius: 50%;
  }

  &:hover {
    color: white;
  }

  &:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }

  span {
    position: relative;
    z-index: 5;
  }

  ${(p) =>
    p.color == "white" &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.palette.darkestGreen};
    `}

  ${(p) =>
    p.color == "white-main" &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.palette.main};
    `}
    
    ${(p) =>
    p.color == "green" &&
    css`
      background-color: ${({ theme }) => theme.palette.main};
      color: white;
    `} /* ${(p) =>
    p.color == "green" &&
    css`
      background-color: ${({ theme }) => theme.palette.main};
      color: white;

      &::before {
        background-color: ${({ theme }) => theme.palette.darkestGreen};
      }
    `}

  ${(p) =>
    p.color == "white" &&
    css`
      background-color: white;
      color: black;
    `}

  ${(p) =>
    p.color === "white-main" &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.palette.main};

      &::before {
        background-color: ${({ theme }) => theme.palette.darkestGreen};
      }

      &:hover {
        color: white;
      }
    `}

    ${(p) =>
    p.color === "light-green" &&
    css`
      background-color: #9fdf21;
      color: #ffffff;
    `}

  ${(p) =>
    p.$opacity &&
    css`
      opacity: 0.9;
      color: black;

      &:hover {
        color: white;
      }
    `}

  &:hover {
    color: white;
  }

  span {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 19.6rem;
    min-height: 4.8rem;
    border-radius: 2.4rem;
    overflow: hidden;
    color: inherit;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      transition: width 0.5s, height 0.5s;
      background-color: ${({ theme }) => theme.palette.darkestGreen};
    }
  }

  span::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 50%;
    width: 0px;
    height: 0px;
    transition: width 0.5s, height 0.5s;
    z-index: 0;
  }

  &:hover span::before {
    width: 400px;
    height: 400px;
  } */
`;
