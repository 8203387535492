import { useStaticQuery, graphql } from "gatsby";
import { combineJobs } from "../utils/combine-jobs";

export const useTotalJobsCount = () => {
  const data = useStaticQuery(graphql`
    query {
      barcelonaJobs: allWorkableJob(
        filter: {
          department: { ne: null },
          location: { city: { eq: "Barcelona" } },
          shortcode: { ne: "4BB602E1A6" } }
      ) {
        nodes {
          shortcode
        }
        totalCount
      }
      remoteJobs: allWorkableJob(
        filter: {
          location: { telecommuting: { eq: true } }
          department: { eq: "Other" }
          shortcode: { ne: "4BB602E1A6" }
        }
      ) {
        nodes {
          shortcode
        }
        totalCount
      }
    }
  `);

  const combinedJobs = combineJobs(data.barcelonaJobs, data.remoteJobs);
  return combinedJobs.totalCount;
};
