import { Variants } from "framer-motion";

export const menuList: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.35,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
};

export const getmenuItemVariant = (index: number, subitem: boolean): Variants => ({
  hidden: {
    x: 400,
    opacity: 0,
    transition: {
      duration: 0,
      delay: 0,
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: subitem ? (index + 1) * 0.1 : index * 0.1,
    },
  },
  hover: {
    scale: 1.15,

    originX: 0,
    textShadow: "0px 0px 2px rgba(0,0,0,0.25)",
    transition: {
      duration: 0.3,
    },
  },
});

export const teamText: Variants = {
  hidden: {
    opacity: 0,
    y: "-50%",
    x: -100,
    rotate: 180,
  },
  visible: {
    opacity: 1,
    x: 0,
    y: "-50%",
    rotate: 180,
    transition: {
      delay: 0.9,
      duration: 0.4,
      type: "spring",
      stiffness: 50,
    },
  },
};

export const badge: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.9,
      duration: 0.4,
      type: "spring",
      stiffness: 100,
    },
  },
};

export const menuButton: Variants = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.3,
    },
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
  },
};
