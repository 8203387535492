import styled from "styled-components";

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.main};
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;

  .lottie {
    width: 100%;
    height: auto;

    @media ${({ theme }) => theme.breakPoints.screen768} {
      width: 50%;
      height: 50%;
    }

    @media ${({ theme }) => theme.breakPoints.screen1024} {
      width: 30%;
      height: 30%;
    }

    @media ${({ theme }) => theme.breakPoints.screen1440} {
    }
  }
`;
