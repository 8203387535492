import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { motion, AnimatePresence } from "framer-motion";
// styles
import * as S from "./Loader.styles";

import loader from "../../assets/lottie/zeptolab_logo.json";
import test from "../../assets/lottie/test.json";

type Props = {
  onLoadComplete?: () => void;
};

export const Loader = ({ onLoadComplete }: Props) => {
  const [animationData, setAnimationData] = useState<any>();

  useEffect(() => {
    import("../../assets/lottie/zeptolab_logo.json").then((data) => {
      setAnimationData(data);
    });
  }, []);

  return (
    <S.LoaderContainer id="loader-wrapper">
      {!animationData ? null : (
        <Lottie
          animationData={animationData}
          loop={false}
          onComplete={onLoadComplete}
          className="lottie"
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
        />
      )}
    </S.LoaderContainer>
  );
};
