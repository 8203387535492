import { Link } from "gatsby";
import styled from "styled-components";

export const Header = styled.header`
  position: relative;
  z-index: 100;
  width: 100%;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 1500px;

  @media ${({ theme }) => theme.breakPoints.screen768} {
    padding: 20px 40px;
  }

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    padding: 20px 60px;
  }

  @media ${({ theme }) => theme.breakPoints.screen1536} {
    padding: 20px 0;
    margin: 0 auto;
  }

  svg {
    fill: #ffffff;
    width: 13rem;
    height: 4.2rem;

    @media ${({ theme }) => theme.breakPoints.screen768} {
      width: 16.5rem;
      height: 5.2rem;
    }

    @media ${({ theme }) => theme.breakPoints.screen1280} {
      width: 20.4rem;
      height: 6.4rem;
    }
  }
`;

export const MainPageButton = styled(Link)`
  display: none;

  @media ${({ theme }) => theme.breakPoints.screen768} {
    position: relative;
    left: -50%;
    transform: translateX(50%);
    display: block;
    color: #fff;
    font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }

  &:hover {
    color: #012f2f;
    text-shadow: none;
  }
`;
