import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { motion } from "framer-motion";
// styles
import * as S from "./Footer.styles";
// components
import { Button } from "../Button/Button";
// icons
import LocationIcon from "../../assets/images/icons/location.inline.svg";
import FooterLogo from "../../assets/images/zeptolab_footer_logo.inline.svg";
import { FollowUs } from "../FollowUs/FollowUs";
import { useTotalJobsCount } from "../../hooks/useTotalJobsCount";

type Link = {
  name: string;
  url: string;
  withBadge?: boolean;
};

type Data = {
  allTeamPagesJson: {
    nodes: {
      slug: string;
    }[];
  };
};

const mainLinks: Link[] = [
  { name: "Careers", url: "/" },
  { name: "Jobs", url: "/join-us", withBadge: true },
];

// TODO: add social links
// TODO: replace location icon
const Footer = () => {
  const data: Data = useStaticQuery(graphql`
    query {
      allTeamPagesJson {
        nodes {
          slug
        }
      }
    }
  `);

  const totalCount = useTotalJobsCount();

  const teamLinks = data.allTeamPagesJson.nodes.map((node) => ({
    name: node.slug.replace(/-/g, " "),
    url: `/teams/${node.slug}`,
  }));

  const order = ["product", "development"];
  const sortedData = teamLinks.sort((a, b) => {
    const aIndex = order.indexOf(a.name);
    const bIndex = order.indexOf(b.name);
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  });

  return (
    <S.Footer>
      <S.FooterContainer>
        <S.FooterTextWrapper>
          <S.MainText>We are always happy to expand our team</S.MainText>
          <S.SecondaryText>
            Found no job opening for you?
            <br />
            Send us your CV, there might be a perfect match in the future!
          </S.SecondaryText>
          <Button color="white-main" link="https://apply.workable.com/zeptolab/j/4BB602E1A6/" width="15.6rem">
            Get in touch
          </Button>
        </S.FooterTextWrapper>
        <S.FooterLinksWrapper>
          <S.MainLinks>
            {mainLinks.map((link) => {
              return totalCount > 0 && link.withBadge ? (
                <S.MainLink key={link.name} to={link.url}>
                  <motion.div
                    whileHover={{
                      scale: 1.07,
                      originX: 0,
                      textShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <span>{link.name}</span>

                    <S.Badge>{totalCount}</S.Badge>
                  </motion.div>
                </S.MainLink>
              ) : (
                <S.MainLink key={link.name} to={link.url}>
                  <motion.div
                    whileHover={{
                      scale: 1.07,
                      originX: 0,
                      textShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    {link.name}
                  </motion.div>
                </S.MainLink>
              );
            })}
          </S.MainLinks>
          <S.SecondaryLinks>
            <S.TeamText>Our teams</S.TeamText>
            <S.TeamLinks>
              {sortedData.map((link) => (
                <S.TeamLink key={link.name} to={link.url}>
                  <motion.div
                    whileHover={{
                      scale: 1.15,
                      originX: 0,
                      textShadow: "0px 0px 2px rgba(0,0,0,0.25)",
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    {link.name}
                  </motion.div>
                </S.TeamLink>
              ))}
            </S.TeamLinks>
          </S.SecondaryLinks>
          <S.LocationWrapper>
            <S.LocationTextContainer>
              <LocationIcon />
              <S.LocationText>ZeptoLab Barcelona</S.LocationText>
            </S.LocationTextContainer>
            <S.LocationSubText>Tallers Area, Avinguda Diagonal, 177, 08018 </S.LocationSubText>
          </S.LocationWrapper>
          <S.LogoWrapper to="/">
            <FooterLogo />
          </S.LogoWrapper>
        </S.FooterLinksWrapper>
        <S.CopyrightText>
          © ZeptoLab UK Limited 2023 | All rights reserved |{" "}
          <a href="https://www.zeptolab.com/privacy">Privacy Policy</a>
        </S.CopyrightText>
      </S.FooterContainer>
      <FollowUs />
    </S.Footer>
  );
};

export default Footer;
