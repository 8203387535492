import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import CloseIcon from "../../assets/images/icons/close.inline.svg";
import { useTotalJobsCount } from "../../hooks/useTotalJobsCount";
// styles
import * as S from "./Menu.styles";
// animations
import { menuList, getmenuItemVariant, teamText, badge, menuButton } from "./Menu.animations";

type Navigation = {
  name?: string;
  path?: string;
  withBadge?: boolean;
  children?: {
    name: string;
    path: string;
  }[];
};

type NavigationChildren = Navigation["children"];

type Data = {
  allTeamPagesJson: {
    nodes: {
      slug: string;
    }[];
  };
};

type Props = {
  active: boolean;
  onClick?: () => void;
};

export const Menu = ({ active, onClick }: Props) => {
  const data: Data = useStaticQuery(graphql`
    query {
      allTeamPagesJson {
        nodes {
          slug
        }
      }
    }
  `);

  const totalCount = useTotalJobsCount();
  const teamLinks: NavigationChildren = data.allTeamPagesJson.nodes.map((node) => ({
    name: node.slug.replace(/-/g, " "),
    path: `/teams/${node.slug}`,
  }));

  const order = ["product", "development"];
  const sortedData = teamLinks.sort((a, b) => {
    const aIndex = order.indexOf(a.name);
    const bIndex = order.indexOf(b.name);
    if (aIndex === -1 && bIndex === -1) {
      return 0;
    } else if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else {
      return aIndex - bIndex;
    }
  });

  const navigation: Navigation[] = [
    {
      name: "Careers",
      path: "/",
      children: [
        {
          name: "Our Strengths",
          path: "/#our-strengths",
        },
        {
          name: "Our Values",
          path: "/#our-values",
        },
        {
          name: "Discover benefits",
          path: "/#discover-benefits",
        },
        {
          name: "Barcelona Office",
          path: "/#barcelona-office",
        },
      ],
    },
    {
      name: "Jobs",
      path: "/join-us",
      withBadge: true,
      children: [
        {
          name: "How we hire",
          path: "/join-us/#how-we-hire",
        },
      ],
    },
    {
      children: sortedData,
    },
    {
      name: "ZeptoLab.com",
      path: "https://zeptolab.com",
    },
  ];

  return (
    <>
      <S.Button onClick={onClick} $isActive={active} variants={menuButton} whileHover="hover">
        <S.ButtonLabel $isActive={active}>Menu</S.ButtonLabel>
        <S.ButtonIcon $isActive={active}>
          <CloseIcon />
        </S.ButtonIcon>
      </S.Button>
      <S.Menu $isActive={active}>
        <S.MenuListWrapper variants={menuList} initial="hidden" animate={active ? "visible" : "hidden"}>
          {navigation.map((item, index) => (
            <S.MenuList key={index}>
              {item.name && (
                <S.MenuListItem
                  variants={getmenuItemVariant(index, false)}
                  initial="hidden"
                  animate={active ? "visible" : "hidden"}
                  whileHover="hover"
                >
                  {item.name === "ZeptoLab.com" ? (
                    <S.MenuLinkRegular href={item.path!} onClick={onClick}>
                      {item.name}
                      {item.withBadge && <S.MenuBadge variants={badge}>{totalCount}</S.MenuBadge>}
                    </S.MenuLinkRegular>
                  ) : (
                    <S.MenuLink to={item.path!} onClick={onClick}>
                      {item.name}
                      {item.withBadge && <S.MenuBadge variants={badge}>{totalCount}</S.MenuBadge>}
                    </S.MenuLink>
                  )}
                </S.MenuListItem>
              )}
              {item.children && (
                <S.MenuList>
                  {index === navigation.length - 2 && <S.OurTeamText variants={teamText}>Our teams</S.OurTeamText>}
                  {item.children.map((child, index) => (
                    <S.MenuListItem
                      key={index.toString()}
                      variants={getmenuItemVariant(index, true)}
                      initial="hidden"
                      animate={active ? "visible" : "hidden"}
                      whileHover="hover"
                    >
                      <S.MenuLink to={child.path} onClick={onClick}>
                        {child.name}
                      </S.MenuLink>
                    </S.MenuListItem>
                  ))}
                </S.MenuList>
              )}
            </S.MenuList>
          ))}
        </S.MenuListWrapper>
        <S.MenuBackgroundWrapper></S.MenuBackgroundWrapper>
      </S.Menu>
    </>
  );
};
