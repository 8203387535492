import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { motion, useTransform, useScroll } from "framer-motion";
// styles
import * as S from "./Header.styles";
// components
import { ResponsiveContainer } from "../ResponsiveContainer/ResponsiveContainer";
import Logo from "../../assets/images/zeptolab_logo.inline.svg";
import { Menu } from "../Menu/Menu";

type Props = {
  currentPage?: string;
};

const Header = ({ currentPage }: Props) => {
  const [active, setActive] = useState(false);
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 150], [1, 0]);

  return (
    <S.Header>
      <S.HeaderWrapper>
        <Link to="/">
          <Logo />
        </Link>
        {currentPage !== "/" && <S.MainPageButton to="/">BACK TO MAIN PAGE</S.MainPageButton>}
        <Menu active={active} onClick={() => setActive(!active)} />
      </S.HeaderWrapper>
    </S.Header>
  );
};

export default Header;
