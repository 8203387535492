import React from "react";
import { AnimatePresence } from "framer-motion";
import { Loader } from "./src/components/Loader/Loader";

export const wrapPageElement = ({ element }) => <AnimatePresence mode="wait">{element}</AnimatePresence>;

export const onRouteUpdate = ({ location }) => {
  const { hash } = location;
  if (hash) {
    const element = document.querySelector(hash);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      return false;
    }
  }

  const body = document.querySelector("body");
  body.scrollTop = 0;
};
