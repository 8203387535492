import React from "react";
// styles
import * as S from "./FollowUs.styles";
// icons
import TwitterIcon from "../../assets/images/icons/twitter.inline.svg";
import YoutubeIcon from "../../assets/images/icons/youtube.inline.svg";
import FacebookIcon from "../../assets/images/icons/facebook.inline.svg";
import TiktokIcon from "../../assets/images/icons/tiktok.inline.svg";
import InstagramIcon from "../../assets/images/icons/insta.inline.svg";
import LinkedinIcon from "../../assets/images/icons/linkedin.inline.svg";

type SocialLinks = {
  name: string;
  icon: React.ReactNode;
  link: string;
};

const socialLinks: SocialLinks[] = [
  { name: "facebook", icon: <FacebookIcon />, link: "https://www.facebook.com/zeptolab" },
  { name: "youtube", icon: <YoutubeIcon />, link: "https://www.youtube.com/user/ZeptoLab" },
  { name: "twitter", icon: <TwitterIcon />, link: "https://twitter.com/ZeptoLab" },
  { name: "tiktok", icon: <TiktokIcon />, link: "https://www.tiktok.com/@zeptolab_official" },
  { name: "instagram", icon: <InstagramIcon />, link: "https://www.instagram.com/zeptolab/" },
  { name: "linkedin", icon: <LinkedinIcon />, link: "https://www.linkedin.com/company/zeptolab/" },
];

// TODO: add social icons and links
export const FollowUs = () => {
  return (
    <S.FollowUsContainer>
      <S.FollowUsTitle>Follow us</S.FollowUsTitle>
      {socialLinks.map((link) => (
        <S.FollowUsLink key={link.name} href={link.link}>
          {link.icon}
        </S.FollowUsLink>
      ))}
    </S.FollowUsContainer>
  );
};
