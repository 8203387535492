import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  palette: {
    white: "#ffffff",
    black: "#000000",
    main: "#85c800",
    background: "#ffffff",
    text: "#000000",
    lighBlue: "#d8efe8",
    darkBlue: "#00acac",
    lightGreen: "#e2ffa9",
    darkGreen: "#015f5f",
    darkestGreen: "#012f2f",
  },
  fonts: {
    gotham: "Gotham, sans-serif",
    cera: "Cera Round Pro, sans-serif",
    electronica: "Electronica Display Solid, sans-serif",
  },
  fontSize: {
    xs: "font-size: 0.64rem",
    sm: "font-size: 0.8rem",
    md: "font-size: 1rem",
    lg: "font-size: 1.25rem",
    xl: "font-size: 1.563rem",
    xl2: "font-size: 1.953rem",
    xl3: "font-size: 2.441rem",
    xl4: "font-size: 3.052rem",
    xl5: "font-size: 3.815rem",
    xl6: "font-size: 4.768rem",
  },
  fontWeight: {
    light: "font-weight: 300",
    normal: "font-weight: 400",
    medium: "font-weight: 500",
    bold: "font-weight: 600",
  },
  typography: {
    fontFamily: {
      gotham: "Gotham, sans-serif",
      cera: "Cera Round Pro, sans-serif",
      electronica: "Electronica Display Solid, sans-serif",
      bebasNeue: "Bebas Neue, cursive",
    },
    size: {
      h1: "96px",
      h2: "60px",
      h3: "48px",
      h4: "34px",
      h5: "24px",
      h6: "20px",
      body1: "16px",
      body2: "14px",
      button: "14px",
      caption: "12px",
      overline: "10px",
    },
    sizes: {
      xs3: "0.45rem",
      xs2: "0.625rem",
      xs: "0.75rem",
      sm: "0.875rem",
      md: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      xl2: "1.5rem",
      xl3: "1.875rem",
      xl4: "2.25rem",
      xl5: "3rem",
      xl6: "3.75rem",
      xl7: "4.5rem",
      xl8: "6rem",
      xl9: "8rem",
    },
  },
  breakPoints: {
    screen640: "(min-width: 640px)",
    screen768: "(min-width: 768px)",
    screen1024: "(min-width: 1024px)",
    screen1280: "(min-width: 1280px)",
    screen1440: "(min-width: 1440px)",
    screen1536: "(min-width: 1536px)",
    screen1920: "(min-width: 1920px)",
  },
};
