import React, { useState } from "react";
import { PageProps } from "gatsby";

// theme
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../theme";
import { theme } from "../theme";
// components
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Loader } from "../components/Loader/Loader";

// lottie

const Layout = ({ children, location }: PageProps) => {
  const url = location.pathname;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header currentPage={url} />
      <main>{children}</main>
      <Footer />
      <Loader />
    </ThemeProvider>
  );
};

export default Layout;
