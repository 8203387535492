import { css } from "styled-components";

export const fonts = css`
  // Cera Round Pro
  @font-face {
    font-family: "Cera Round Pro";
    src: url("/fonts/cera/CeraRoundPro-Bold.eot");
    src: url("/fonts/cera/CeraRoundPro-Bold.eot?#iefix") format("embedded-opentype"),
      url("/fonts/cera/CeraRoundPro-Bold.woff2") format("woff2"),
      url("/fonts/cera/CeraRoundPro-Bold.woff") format("woff"),
      url("/fonts/cera/CeraRoundPro-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Cera Round Pro";
    src: url("/fonts/cera/CeraRoundPro-Light.eot");
    src: url("/fonts/cera/CeraRoundPro-Light.eot?#iefix") format("embedded-opentype"),
      url("/fonts/cera/CeraRoundPro-Light.woff2") format("woff2"),
      url("/fonts/cera/CeraRoundPro-Light.woff") format("woff"),
      url("/fonts/cera/CeraRoundPro-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Cera Round Pro";
    src: url("/fonts/cera/CeraRoundPro-Regular.eot");
    src: url("/fonts/cera/CeraRoundPro-Regular.eot?#iefix") format("embedded-opentype"),
      url("/fonts/cera/CeraRoundPro-Regular.woff2") format("woff2"),
      url("/fonts/cera/CeraRoundPro-Regular.woff") format("woff"),
      url("/fonts/cera/CeraRoundPro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Cera Round Pro";
    src: url("/fonts/cera/CeraRoundPro-Black.eot");
    src: url("/fonts/cera/CeraRoundPro-Black.eot?#iefix") format("embedded-opentype"),
      url("/fonts/cera/CeraRoundPro-Black.woff2") format("woff2"),
      url("/fonts/cera/CeraRoundPro-Black.woff") format("woff"),
      url("/fonts/cera/CeraRoundPro-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Cera Round Pro";
    src: url("/fonts/cera/CeraRoundPro-Medium.eot");
    src: url("/fonts/cera/CeraRoundPro-Medium.eot?#iefix") format("embedded-opentype"),
      url("/fonts/cera/CeraRoundPro-Medium.woff2") format("woff2"),
      url("/fonts/cera/CeraRoundPro-Medium.woff") format("woff"),
      url("/fonts/cera/CeraRoundPro-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Cera Round Pro";
    src: url("/fonts/cera/CeraRoundPro-Thin.eot");
    src: url("/fonts/cera/CeraRoundPro-Thin.eot?#iefix") format("embedded-opentype"),
      url("/fonts/cera/CeraRoundPro-Thin.woff2") format("woff2"),
      url("/fonts/cera/CeraRoundPro-Thin.woff") format("woff"),
      url("/fonts/cera/CeraRoundPro-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
  }

  // Electronica
  @font-face {
    font-family: "Electronica Display Solid";
    src: url("/fonts/electronica/ElectronicaDisplaySolid.eot");
    src: url("/fonts/electronica/ElectronicaDisplaySolid.eot?#iefix") format("embedded-opentype"),
      url("/fonts/electronica/ElectronicaDisplaySolid.woff2") format("woff2"),
      url("/fonts/electronica/ElectronicaDisplaySolid.woff") format("woff"),
      url("/fonts/electronica/ElectronicaDisplaySolid.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  // Gotham
  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-Bold.eot");
    src: url("/fonts/gotham/Gotham-Bold.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Bold.woff2") format("woff2"), url("/fonts/gotham/Gotham-Bold.woff") format("woff"),
      url("/fonts/gotham/Gotham-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-Book.eot");
    src: url("/fonts/gotham/Gotham-Book.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Book.woff2") format("woff2"), url("/fonts/gotham/Gotham-Book.woff") format("woff"),
      url("/fonts/gotham/Gotham-Book.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-Medium.eot");
    src: url("/fonts/gotham/Gotham-Medium.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Medium.woff2") format("woff2"), url("/fonts/gotham/Gotham-Medium.woff") format("woff"),
      url("/fonts/gotham/Gotham-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-Light.eot");
    src: url("/fonts/gotham/Gotham-Light.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Light.woff2") format("woff2"), url("/fonts/gotham/Gotham-Light.woff") format("woff"),
      url("/fonts/gotham/Gotham-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-Thin.eot");
    src: url("/fonts/gotham/Gotham-Thin.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Thin.woff2") format("woff2"), url("/fonts/gotham/Gotham-Thin.woff") format("woff"),
      url("/fonts/gotham/Gotham-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-Black.eot");
    src: url("/fonts/gotham/Gotham-Black.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Black.woff2") format("woff2"), url("/fonts/gotham/Gotham-Black.woff") format("woff"),
      url("/fonts/gotham/Gotham-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham Ultra";
    src: url("/fonts/gotham/Gotham-Ultra.eot");
    src: url("/fonts/gotham/Gotham-Ultra.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-Ultra.woff2") format("woff2"), url("/fonts/gotham/Gotham-Ultra.woff") format("woff"),
      url("/fonts/gotham/Gotham-Ultra.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/fonts/gotham/Gotham-XLight.eot");
    src: url("/fonts/gotham/Gotham-XLight.eot?#iefix") format("embedded-opentype"),
      url("/fonts/gotham/Gotham-XLight.woff2") format("woff2"), url("/fonts/gotham/Gotham-XLight.woff") format("woff"),
      url("/fonts/gotham/Gotham-XLight.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }
`;
