import React, { useState } from "react";

export const useMouseMove = (): [{ x: number; y: number }, (e: React.MouseEvent) => void] => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent) => {
    const button = e.currentTarget;
    const { left, top } = button?.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    setCoords({ x, y });
  };

  return [coords, handleMouseMove];
};
