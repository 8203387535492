import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Button = styled(motion.button)<{ $isActive: boolean }>`
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 999;
  width: 5rem;
  height: 5rem;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.palette.main};
  cursor: pointer;
  transition: all 350ms cubic-bezier(0.54, 0.38, 0.26, 0.92);
  box-shadow: ${({ $isActive, theme }) =>
    $isActive ? "none" : "0 4px 6px rgba(0, 48, 48, 0.1), 0 1px 3px rgba(0, 48, 48, 0.08)"};

  @media ${({ theme }) => theme.breakPoints.screen768} {
    right: 4rem;
    width: 7.2rem;
    height: 7.2rem;
    font-size: 1.4rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    right: 4rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    width: 10rem;
    height: 10rem;
    font-size: 1.6rem;
  }
`;

export const ButtonLabel = styled.span<{ $isActive: boolean }>`
  position: absolute;
  opacity: ${({ $isActive }) => ($isActive ? "0" : "1")};
  transform: ${({ $isActive }) => ($isActive ? "scale(0)" : "scale(1)")};
  transition: ${({ $isActive }) =>
    $isActive ? "all 600ms cubic-bezier(0.58, 0.45, 0.27, 1.1)" : "all 350ms cubic-bezier(0.54, 0.38, 0.26, 0.92)"};
`;

export const ButtonIcon = styled.span<{ $isActive: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  opacity: ${({ $isActive }) => ($isActive ? "1" : "0")};
  transform: ${({ $isActive }) => ($isActive ? "scale(1) rotateZ(360deg)" : "scale(0) rotateZ(0deg)")};
  transition: ${({ $isActive }) =>
    $isActive ? "all 600ms cubic-bezier(0.58, 0.45, 0.27, 1.1)" : "all 350ms cubic-bezier(0.54, 0.38, 0.26, 0.92)"};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Menu = styled.nav<{ $isActive: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 800;
  border-radius: none;
  width: 100vw;
  height: 100%;
  padding: 6.5rem 5rem;
  background-color: ${({ theme }) => theme.palette.main};
  clip-path: ${({ $isActive }) =>
    $isActive ? "circle(100%)" : "circle(2.5rem at calc(100% - 4.5rem) calc(2.5rem + 2rem))"};
  transition: ${({ $isActive }) =>
    $isActive
      ? "clip-path 600ms cubic-bezier(0.58, 0.45, 0.27, 1.1)"
      : "clip-path 350ms cubic-bezier(0.54, 0.38, 0.26, 0.92)"};

  @media ${({ theme }) => theme.breakPoints.screen768} {
    width: 50vw;
    box-shadow: 0 4px 6px rgba(0, 48, 48, 0.1), 0 1px 3px rgba(0, 48, 48, 0.08);
    clip-path: ${({ $isActive }) =>
      $isActive ? "circle(100%)" : "circle(3.6rem at calc(100% - 7.6rem) calc(3.6rem + 2rem))"};
  }

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    width: 40vw;
    clip-path: ${({ $isActive }) =>
      $isActive ? "circle(100%)" : "circle(3.6rem at calc(100% - 7.6rem) calc(3.6rem + 2rem))"};
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    width: 25vw;
    clip-path: ${({ $isActive }) =>
      $isActive ? "circle(100%)" : "circle(5rem at calc(100% - 9rem) calc(5rem + 2rem))"};
  }
`;

export const MenuListWrapper = styled(motion.div)`
  position: relative;
  z-index: 860;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > ul {
    margin-bottom: 2rem;
  }

  & > ul > li {
    margin-bottom: 1.2rem;
  }

  & > ul > li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000000;
    font-size: 2.6rem;
    font-weight: 500;
    text-decoration: none;
    text-transform: none;

    @media ${({ theme }) => theme.breakPoints.screen1024} {
      font-size: 2.8rem;
    }

    @media ${({ theme }) => theme.breakPoints.screen1280} {
      font-size: 3.2rem;
    }
  }
`;

export const MenuList = styled(motion.ul)`
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & & {
    padding-left: 4rem;

    @media ${({ theme }) => theme.breakPoints.screen1024} {
      padding-left: 5rem;
    }

    @media ${({ theme }) => theme.breakPoints.screen1280} {
      padding-left: 6rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MenuListItem = styled(motion.li)`
  margin-bottom: 0.6rem;
  color: #000000;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MenuLink = styled(Link)`
  color: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 1.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 2rem;
  }
`;

export const MenuLinkRegular = styled.a`
  color: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 1.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 2rem;
  }
`;

export const MenuBadge = styled(motion.span)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #ffffff;
  color: #85c800;
  font-size: 2.2rem;
  font-weight: 600;
`;

export const OurTeamText = styled(motion.li)`
  position: absolute;
  height: max-content;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotateZ(180deg);
  color: rgba(255, 255, 255, 0.46);
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  text-orientation: mixed;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 3.45rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 3.8rem;
  }
`;

export const MenuBackgroundWrapper = styled.div`
  position: absolute;
  bottom: -4px;
  right: 0;
  width: 100%;
  height: 100%;

  opacity: 0.46;
  z-index: 850;

  & > svg {
    transform: rotateZ(180deg) scaleX(-1);
    width: 100%;
    height: 100%;
  }
`;
