import styled from "styled-components";
import { Link as GatsbyLink, Link } from "gatsby";

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.main};
  font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 36px 20px 12px;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  @media ${({ theme }) => theme.breakPoints.screen768} {
    padding: 40px 40px 12px;
  }

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    padding: 60px 60px 12px;
    grid-template-columns: 0.7fr 1.3fr;
    column-gap: 4.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    padding: 80px 60px 12px;
    grid-template-columns: 0.6fr 1.4fr;
    column-gap: 9.6rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1536} {
    padding: 100px 0 12px;
  }
`;

export const FooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  margin-bottom: 4.4rem;
`;

export const MainText = styled.div`
  color: ${({ theme }) => theme.palette.white};
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.25;

  @media ${({ theme }) => theme.breakPoints.screen768} {
    font-size: 2.6rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 2.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 3rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1536} {
    font-size: 3.6rem;
  }
`;

export const SecondaryText = styled.div`
  color: ${({ theme }) => theme.palette.black};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.23rem;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 1.6rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 1.8rem;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4.4rem;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;
  }
`;

export const MainLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const MainLink = styled(GatsbyLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.palette.black};
  font-size: 2.6rem;
  font-weight: 500;
  text-decoration: none;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 2.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 3.2rem;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.8rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.main};
  font-size: 2.2rem;
  font-weight: 600;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    width: 4.8rem;
    height: 4.8rem;
    font-size: 2.4rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    width: 5.6rem;
    height: 5.6rem;
    margin-left: 1.2rem;
    font-size: 2.8rem;
  }
`;

export const SecondaryLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
`;

export const TeamLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TeamLink = styled(GatsbyLink)`
  color: ${({ theme }) => theme.palette.black};
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 1.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 2rem;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const TeamText = styled.div`
  position: relative;
  transform: rotateZ(180deg);
  color: rgba(255, 255, 255, 0.46);
  font-size: 3.2rem;
  font-weight: 500;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: mixed;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 3.6rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 4rem;
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    align-self: start;
  }
`;

export const LocationTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;

  align-items: flex-start;

  svg {
    position: relative;
    bottom: 0.2rem;
    width: 1.8rem;

    @media ${({ theme }) => theme.breakPoints.screen1024} {
      width: 2.4rem;
    }

    @media ${({ theme }) => theme.breakPoints.screen1440} {
      width: 2.6rem;
      bottom: 0.7rem;
    }
  }
`;

export const LocationText = styled.div`
  color: ${({ theme }) => theme.palette.black};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 1.8rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 2rem;
  }
`;

export const LocationSubText = styled.div`
  color: ${({ theme }) => theme.palette.black};
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0.6rem;
  margin-left: 2.1rem;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    font-size: 1.4rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 1.6rem;
  }
`;

export const LogoWrapper = styled(Link)`
  width: 8.4rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: end;
  justify-self: end;

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    width: 9.2rem;
    grid-column: 3;
    grid-row: 2;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    width: 10.2rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const CopyrightText = styled.div`
  color: ${({ theme }) => theme.palette.black};
  font-size: 1rem;
  font-weight: 300;
  margin-top: 2.4rem;

  a {
    color: ${({ theme }) => theme.palette.black};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${({ theme }) => theme.breakPoints.screen1024} {
    grid-column: 1 / -1;
    grid-row: 3;
    margin-top: 0;
    font-size: 1.2rem;
  }

  @media ${({ theme }) => theme.breakPoints.screen1280} {
    font-size: 1.4rem;
  }
`;
