import styled from "styled-components";

export const FollowUsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: ${({ theme }) => theme.palette.darkestGreen};
  color: ${({ theme }) => theme.palette.white};
`;

export const FollowUsTitle = styled.span`
  display: none;

  @media ${({ theme }) => theme.breakPoints.screen768} {
    display: inline-flex;
    flex: 2 1 0;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.white};
    font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    border-right: 1px solid ${({ theme }) => theme.palette.white};
  }
`;

export const FollowUsLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  height: 6rem;
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.white};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.palette.white};
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    transition: all 0.2s ease-in-out;
  }

  &:hover svg {
    scale: 1.3;
  }
`;
