type Job = {
  title: string;
  description: string;
  location: {
    city: string;
    telecommuting: boolean;
  };
  department: string;
  shortcode: string;
};

type QueryResult = {
  totalCount: number;
  nodes: Job[];
};

export const combineJobs = (result1: QueryResult, result2: QueryResult): QueryResult => {
  const allNodesMap: { [key: string]: Job } = {};
  result1.nodes.forEach((node) => {
    allNodesMap[node.shortcode] = node;
  });
  result2.nodes.forEach((node) => {
    allNodesMap[node.shortcode] = node;
  });
  const combinedNodes = Object.values(allNodesMap);
  const combinedTotalCount = result1.totalCount + result2.totalCount;

  return { totalCount: combinedNodes.length, nodes: combinedNodes };
};
