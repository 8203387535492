import React, { useState, useEffect } from "react";
import { useMouseMove } from "../../hooks/useMouseMove";
import * as S from "./Button.styles";

type Props = {
  children: React.ReactNode;
  color?: "green" | "white" | "white-main" | "light-green";
  opacity?: boolean;
  link: string;
  width?: string;
};

export const Button = ({ children, color = "green", opacity = false, link, width, ...rest }: Props) => {
  const [coords, handleMouseMove] = useMouseMove();

  const styles: React.CSSProperties = {
    ["--x" as any]: `${coords.x}px`,
    ["--y" as any]: `${coords.y}px`,
  };

  return (
    <S.Container
      to={link}
      style={styles}
      color={color}
      onMouseMove={handleMouseMove}
      $opacity={opacity}
      {...rest}
      width={width}
    >
      <span>{children}</span>
    </S.Container>
  );
};
